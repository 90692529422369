<template>
    <div class="login-wrapper">
        <the-header/>
        <div class="login-card-wrapper">
            <div class="login-card bg-blur loginPage">
                <!-- <div class="login-title">Login</div> -->
                <!-- <div class="logo-container login-title">
                    <img src="/orla/logo.png"/>
                </div> -->
                <div class="login-outer-wrapper">
                    <h2>Welcome Back!</h2>
                    <p>Please login with your user information below.</p>
                </div>
                <div class="login-inputs">
                    <div>
                        <label>Username</label>
                        <input type="email" placeholder="Enter your username" required v-model="form.username"/>
                    </div>
                    <div>
                        <label>Password</label>
                        <input type="password" placeholder="Enter your password" required v-model="form.password"/>
                    </div>
                </div>
                <div class="login-checkbox">
                    <div></div>
                    <router-link to="/forget_password">Forgot Password?</router-link>
                </div>
                <div class="login-subdiv">
                    <button class="login-button" @click="onSubmit">Login</button>
                    <div class="signUpCls">Not a customer?
                        <router-link to="orla-signup">Sign Up</router-link>
                    </div>
                </div>
            </div>
        </div>
        <the-footer/>
    </div>
</template>
<script>

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import '@/orla/partials/style.css';
import '@/orla/partials/responsive.css';
import TheHeader from '@/orla/partials/Header.vue';
import TheFooter from '@/orla/partials/Footer.vue';

export default {
    components: {
        TheHeader,
        TheFooter,
    },
    data() {
        return {
            loading: false,
            form: {
                username: "",
                password: "",
            },
            errors: {
                invalid: "",
            },
            siteName: "",
            year: new Date().getFullYear(),
        };
    },
    created: function () {
        if (this.$route.query.email && this.$route.query.password) {
            this.form.username = this.$route.query.email;
            this.form.password = this.$route.query.password;

            this.onSubmit();
        }
        this.siteName = 'ORLA';
    },
    methods: {
        onSubmit() {
            this.loading = true;
            this.$http
                .post("user/login", {
                    email: this.form.username,
                    password: this.form.password,
                })
                .then((resp) => {
                    localStorage.setItem("hot-token", resp.data.token);
                    localStorage.setItem("hot-user", resp.data.role);
                    localStorage.setItem("hot-logged-user", resp.data.user_id);
                    localStorage.setItem("hot-user-full-name", resp.data.full_name);
                    let headers = {
                        authorization: "Bearer " + resp.data.token,
                        "content-type": "application/json",
                    };
                    this.$http.defaults.headers.authorization =
                        "Bearer " + resp.data.token;
                    let admin = "";
                    let level = "";

                    switch (resp.data.role) {
                        case "super-admin":
                            admin = "super_admin";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/managerdata",
                                    {
                                        email: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    this.$router.push("/dashboard");
                                });

                            break;
                        case "company-admin":
                            admin = "admin";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/data",
                                    {
                                        email: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    if (resp.data.level) {
                                        localStorage.setItem("hot-company-level", "parent");
                                    } else {
                                        localStorage.setItem("hot-company-level", "child");
                                    }
                                    localStorage.setItem("hot-admin-id", resp.data.admin_id);
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    localStorage.setItem("hot-company-name", resp.data[0].name);
                                    this.$router.push("/dashboard");
                                });
                            break;
                        case "manager":
                            admin = "manager";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/managerdata",
                                    {
                                        email: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    localStorage.setItem("hot-user-name", resp.data[0].full_name);
                                    localStorage.setItem(
                                        "hot-user-number",
                                        resp.data[0].phone_num
                                    );
                                    localStorage.setItem(
                                        "hot-user-2fa",
                                        resp.data[0].is_2f_authenticated
                                    );
                                    this.$router.push("/dashboard");
                                });
                            break;
                        case "employee":
                            admin = "employee";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "employees/user_data",
                                    {
                                        user_name: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    localStorage.setItem("hot-user-name", resp.data[0].full_name);
                                    localStorage.setItem(
                                        "hot-user-number",
                                        resp.data[0].phone_num
                                    );
                                    localStorage.setItem(
                                        "hot-user-2fa",
                                        resp.data[0].is_2f_authenticated
                                    );
                                    if (resp.data[0].employee_status == 0) {
                                        this.errors.invalid = "Account is Deactivated by Admin..!!";
                                    } else {
                                        this.$router.push("/dashboard");
                                    }
                                });
                            break;
                        case "sub-admin":
                            admin = "sub_admin";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/managerdata",
                                    {
                                        email: this.form.username,
                                    },
                                    {headers}
                                )
                                .then((resp) => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    this.$router.push("/dashboard");
                                });

                            break;
                        default:
                            //this.$router.push("/login");
                            this.errors.invalid = "Not Valid..!!";
                    }
                })
                .catch(function (error) {
                    let errorText = "Something went wrong! Please try again later.";
                    if (error.response && error.response.status === 422) {
                        errorText = error.response.data.message;
                    }
                    self.processing = false;
                    Swal.fire({
                        title: "Error!",
                        html: errorText,
                        icon: "error",
                    });
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>
